<template>
  <v-container
    fluid
    fill-height
    class="active-voucher-detail pb-0 pt-12 px-0"
    ref="contentToPrint"
    id="contentToPrint"
  >
    <v-layout>
      <v-flex
        xs12
        class="d-flex flex-column align-center justify-space-between"
      >
        <div class="d-flex flex-column text-center px-2 flex-grow-1">
          <h2 class="primary--text pre-wrap">
            {{ formatedName }}
          </h2>
          <barcode
            :value="coupon.giftCode"
            format="EAN13"
            :displayValue="false"
            class="align-self-center"
            height="80px"
            flat
          />
          <div class="text-body-0">
            {{ $t("coupons.discounts.numericalCode") }}
          </div>
          <h1 class="default--text mt-0">{{ coupon.giftCode }}</h1>
          <div class="mb-7">
            {{
              $t("coupons.discounts.valid", {
                startDate: this.$dayjs(coupon.startDate, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                ),
                endDate: this.$dayjs(coupon.endDate, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )
              })
            }}
            <h5 class="pre-line" style="font-size:small">
              {{ formattedCouponDescription }}
            </h5>
          </div>
          <!--div class="text-body-0 px-5 pre-wrap" v-html="formatedDesc"></div-->
          <ol class="mt-3 text-body-3 disclaimer-messages">
            <li
              v-for="(disclaimer, index) in disclaimerMessages"
              :key="'disclaimer' + index"
            >
              {{ $t(disclaimer) }}
            </li>
          </ol>
        </div>
        <div
          class="bottom-section d-flex flex-column align-center grey lighten-1 text-center px-2 w-100 py-6 mt-3"
        >
          <img
            :src="coupon.categoryProposal.img"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="coupon-img rounded-lg"
            :alt="coupon.categoryProposal.imgAlt"
          />
          <div
            @click="print"
            class="pt-2 pb-4 text-body-0 default--text text-decoration-underline"
            data-html2canvas-ignore="true"
          >
            <v-icon class="mr-2" color="default">$download</v-icon
            ><span>{{ $t("coupons.discounts.downloadYourVoucher") }}</span>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<style lang="scss">
.active-voucher-detail {
  text-align: center;
  .coupon-img {
    max-width: 200px;
    width: calc(100vw - 20px);
  }
  .disclaimer-messages {
    text-align: left;
  }
  .bottom-section {
    position: sticky;
    bottom: 0;
  }
  h2 {
    line-height: 30px;
  }
}
</style>

<script>
import html2pdf from "html2pdf.js";
import downloadDocumentMixin from "~/mixins/downloadDocument";
import matomoService from "@/service/analytics/matomoService";

export default {
  name: "VoucherDetail",
  mixins: [downloadDocumentMixin],
  props: {
    coupon: { type: Object, required: true }
  },
  components: {},
  data() {
    return {
      disclaimerMessages: []
    };
  },
  mounted(){
    matomoService.trackPageView("/couponDetails");
  },
  computed: {
    formatedName() {
      return this.coupon.name.replace(/\|/g, "\n");
    },
    formattedCouponDescription() {
      return this.coupon.description
        .replace(/\|\*\*\*\*\*\*\|/g, "\n")
        .replace(/\|/g, " ");
    },
    formatedDesc() {
      return this.coupon.description.replace(/\|/g, "\n");
    },
    amount() {
      return this.coupon.userGiftCertificates[0].amount;
    },
    discountType() {
      let type = this.coupon.giftAdjustmentType.descr;
      switch (type) {
        case "PercentOff":
          return "%";
        case "FixedAmounOff":
          return "€";
        default:
          return "";
      }
    }
  },
  methods: {
    print() {
      const element = document.getElementById("contentToPrint");
      let title = this.amount + this.discountType + this.formatedName;
      const opt = {
        margin: 8,
        filename: title,
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
        image: { type: "jpeg", quality: 0.9 }
      };
      element.classList.add("only-on-pdf");
      html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        .then(pdf => {
          element.classList.remove("only-on-pdf");
          const pdfDataUrl = pdf.output("bloburl");
          this.handleReceiptDownload(pdfDataUrl, title);
        });
    }
  },
  async created() {
    this.disclaimerMessages = this.geti18nObject(
      "coupons.discounts.forYouDisclaimer"
    );
  }
};
</script>
